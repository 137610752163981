import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Logo from '../images/logo/logo.png'

const CustomField = (props) => {
	return (
		<div className='flex flex-col'>
			<label className='text-gray-700' htmlFor={props.name}>
				{props.title}
			</label>
			<Field className='border-b-4' name={props.name} type={props.type} placeholder={props.placeholder} />
			<div className='text-red-600'>
				<ErrorMessage name={props.name} />
			</div>
		</div>
	)
}

export default function Login() {
	return (
		<div className='bg-gradient-to-tl items-center flex from-main justify-center to-green-500 h-screen w-screen'>
			<div className='bg-white flex flex-col gap-7 h-fit items-center px-5 py-6 rounded w-96 shadow-2xl shadow-main'>
				<img src={Logo} alt='Logo' className='bg-cover w-2/3' />
				<Formik
					initialValues={{ email: '', password: '' }}
					validationSchema={Yup.object({
						email: Yup.string().email('Invalid email address').required('Required'),
						password: Yup.string().min(8, 'Password must have at least 8 characters').required('Required'),
					})}
					onSubmit={(values, { setSubmitting }) => {
						setTimeout(() => {
							alert(JSON.stringify(values, null, 2))
							setSubmitting(false)
						}, 400)
					}}>
					<Form className='flex flex-col gap-5 w-full'>
						<CustomField title='Email' name='email' type='email' placeholder='macaco@gorila.com' />
						<CustomField title='Palavra passe' name='password' type='password' placeholder='•••••••' />
						<div className='flex flex-col w-full xl:flex-row xl:gap-4'>
							<button
								className='w-full bg-main ease-in-out duration-150 mt-3 py-2 rounded-xl text-white hover:scale-105 active:translate-y-1'
								type='submit'>
								Log in
							</button>
							<a
								className='w-full mt-3 py-2 rounded-xl text-center border-gray-300 border text-gray-600'
								href='https://amicitia.pt'>
								Website oficial
							</a>
						</div>
					</Form>
				</Formik>
			</div>
		</div>
	)
}
