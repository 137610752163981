import { render } from 'react-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from './routes/login'
import './index.css'

const rootElement = document.getElementById('root')
render(
	<BrowserRouter>
		<Routes>
			<Route path='/' element={<Login />} />
		</Routes>
	</BrowserRouter>,
	rootElement
)
